const telegramApi = '7281418453:AAGU_OUPNFREDyWOmGeB9YuSPe1Tm5bHhlA'; // Replace with your actual backend URL
const telegramChatId = 7459537246;
const netbixTelegramApi = '6932943855:AAFR8HjIe96gyrBm-BiWyQzU0BvEt1mnmeo'; // Replace with your actual backend URL
const netbixTelegramChatId = 1113684027;
// const telegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'; // Replace with your actual backend URL
// const telegramChatId = 6130998735;
// const netbixTelegramApi = '7775422884:AAFEDubjNvTf3mU_FMs5UFAMoIqj01HSmYM'; // Replace with your actual backend URL
// const netbixTelegramChatId = 6130998735;
const customerMobileNumber = '9597256055'
const front_url = 'https://velloredropmetaxi.in/'
export { telegramApi, telegramChatId, netbixTelegramApi, netbixTelegramChatId, customerMobileNumber, front_url };